import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Header from '../../Header';
import './styles.css';

class Congrats extends React.PureComponent {
  render() {
    const { stepTitle, t, error, onClickBackButton } = this.props;
    return !error ? (
      <div>
        <Container>
          <Header title={t('congrats.title')} subTitle={stepTitle} />
          <Row className="content">
            <Col xs="7">
              <p className="content-text">
                En breve nos pondremos en contacto contigo para indicarte los siguientes pasos
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    ) : (
      <div>
        <Container>
          <Header subTitle="Vaya!" onClickBackButton={onClickBackButton} />
          <Row className="content">
            <Col xs="7">
              <p className="content-text"> Ha habido un error con el servidor</p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Congrats.propTypes = {
  t: PropTypes.func.isRequired,
  stepTitle: PropTypes.string,
  error: PropTypes.string,
  onClickBackButton: PropTypes.func
};

Congrats.defaultProps = {
  stepTitle: null,
  error: null,
  onClickBackButton: null
};

export default withTranslation()(Congrats);
