import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Input, FormFeedback, Label, Form as Formulario, FormGroup, Spinner } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import NextButton from '../../NextButton';
import Header from '../../Header';
import './styles.css';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.name,
      surname: props.surname,
      email: props.email,
      phone: props.phone,
      errName: null,
      errSurname: null,
      errEmail: null,
      errPhone: null
    };
  }

  onSelectOption = async () => {
    await this.handleValidationName();
    await this.handleValidationSurname();
    await this.handleValidationEmail();
    await this.handleValidationPhone();
    const { errName, errSurname, errEmail, errPhone, name, surname, email, phone } = this.state;
    const { onSelectOption } = this.props;

    if (!errName && !errSurname && !errEmail && !errPhone) {
      onSelectOption({
        name,
        surname,
        email,
        phone
      });
    }
  };

  handleTextInputChangeName = event => {
    this.setState({ name: event.target.value });
  };

  handleTextInputChangeSurname = event => {
    this.setState({ surname: event.target.value });
  };

  handleTextInputChangeEmail = event => {
    this.setState({ email: event.target.value });
  };

  handleTextInputChangePhone = event => {
    this.setState({ phone: event.target.value });
  };

  handleValidationName = () => {
    const { name: userName } = this.state;
    if (userName.trim()) {
      this.setState({ errName: null });
    } else {
      this.setState({ errName: 'El campo nombre está vacío' });
    }
  };

  handleValidationSurname = () => {
    const { surname } = this.state;
    if (surname.trim()) {
      this.setState({ errSurname: null });
    } else {
      this.setState({ errSurname: 'El campo apellido está vacío' });
    }
  };

  handleValidationEmail = () => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { email } = this.state;
    if (email) {
      if (re.test(String(email).toLowerCase())) {
        this.setState({ errEmail: null });
      } else {
        this.setState({ errEmail: 'El campo contiene carácteres incorrectos' });
      }
    } else {
      this.setState({ errEmail: 'El campo email está vacío' });
    }
  };

  handleValidationPhone = () => {
    const { phone } = this.state;
    if (phone) {
      this.setState({ errPhone: null });
    } else {
      this.setState({ errPhone: 'El campo teléfono está vacío' });
    }
  };

  render() {
    const { stepTitle, t, onClickBackButton, isFetchingMortgageOptions } = this.props;
    const { errName, errSurname, errEmail, errPhone } = this.state;
    if (isFetchingMortgageOptions) {
      return (
        <Container>
          <Formulario>
            <Header title={t('form.title')} subTitle={stepTitle} onClickBackButton={onClickBackButton} />
            <Row className="content">
              <Col xs="7">
                {isFetchingMortgageOptions && (
                  <div className="spinnerContainer">
                    <Spinner color="primary" />
                  </div>
                )}
              </Col>
            </Row>
          </Formulario>
        </Container>
      );
    }
    return (
      <Container>
        <Formulario>
          <Header title={t('form.title')} subTitle={stepTitle} onClickBackButton={onClickBackButton} />
          <Row className="content">
            <Col xs="7">
              <div className="wrapper">
                <FormGroup className="content_first">
                  <Label className="content_label" for="name">
                    Nombre
                  </Label>
                  <Input
                    className="textInput_form"
                    placeholder="Nombre"
                    type="text"
                    id="name"
                    required
                    value={this.name}
                    onChange={this.handleTextInputChangeName}
                    invalid={errName || false}
                  />
                  <FormFeedback className="textInput-feedback">{errName}</FormFeedback>
                  <Label className="content_label" for="surname">
                    Apellidos
                  </Label>
                  <Input
                    className="textInput_form textInput_form-lower"
                    placeholder="Apellidos"
                    type="text"
                    id="surname"
                    required
                    value={this.surname}
                    onChange={this.handleTextInputChangeSurname}
                    invalid={errSurname}
                  />
                  <FormFeedback className="textInput-feedback">{errSurname}</FormFeedback>
                </FormGroup>
                <FormGroup className="content_second">
                  <Label className="content_label" for="email">
                    E-mail
                  </Label>
                  <Input
                    className="textInput_form"
                    placeholder="Email"
                    type="email"
                    id="email"
                    required
                    value={this.email}
                    onChange={this.handleTextInputChangeEmail}
                    invalid={errEmail}
                  />
                  <FormFeedback className="textInput-feedback">{errEmail}</FormFeedback>
                  <Label className="content_label textInput_form-lower" for="phone">
                    Número Teléfono
                  </Label>
                  <Input
                    className="textInput_form textInput_form-lower"
                    placeholder="Número teléfono"
                    type="number"
                    id="phone"
                    required
                    value={this.phone}
                    onChange={this.handleTextInputChangePhone}
                    invalid={errPhone}
                  />
                  <FormFeedback className="textInput-feedback">{errPhone}</FormFeedback>
                </FormGroup>
              </div>
              <div className="textInput_form_wrapper">
                <NextButton className="textInput_form_button" onClick={this.onSelectOption}>
                  {t('common.next')}
                </NextButton>
              </div>
            </Col>
          </Row>
        </Formulario>
      </Container>
    );
  }
}

Form.propTypes = {
  t: PropTypes.func.isRequired,
  onSelectOption: PropTypes.func.isRequired,
  onClickBackButton: PropTypes.func,
  stepTitle: PropTypes.string,
  name: PropTypes.string,
  surname: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.number,
  isFetchingMortgageOptions: PropTypes.bool
};

Form.defaultProps = {
  stepTitle: null,
  onClickBackButton: null,
  name: '',
  surname: '',
  email: '',
  phone: null,
  isFetchingMortgageOptions: false
};

export default withTranslation()(Form);
