import config from '../config';

const storeUsersData = async ({ name, surname, email, phone, price, provinceId, savings, income, option }) => {
  const response = await fetch('https://trioteca.com/api/v2/widget/store-request', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      client: {
        name,
        surname,
        email,
        phone,
        utm_source: config.source[process.env.REACT_APP_CLIENT_NAME],
        utm_medium: 'floating',
        utm_campaign: 'listing'
      },
      mortgage: {
        price,
        province_id: provinceId,
        income,
        savings
      },
      proposal: option
    })
  });
  const results = await response.json();
  if (!results) {
    throw new Error('Unexpected response: missing results');
  }

  return { results };
};

export default storeUsersData;
