import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from './translations';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import clientTheme from './constants/clientTheme';

import App from './App';

const clientName = process.env.REACT_APP_CLIENT_NAME;

window.WebFont.load({
  google: {
    families: [`${clientTheme.fontFamily[clientName]}:400,800`]
  }
});

document.documentElement.style.setProperty('--firstBorder', clientTheme.firstBorder[clientName]);
document.documentElement.style.setProperty('--secondBorder', clientTheme.secondBorder[clientName]);
document.documentElement.style.setProperty('--jumbotronBackground', clientTheme.jumbotronBackground[clientName]);
document.documentElement.style.setProperty('--jumbotronColor', clientTheme.jumbotronColor[clientName]);
document.documentElement.style.setProperty('--subtitleBorder', clientTheme.subtitleBorder[clientName]);
document.documentElement.style.setProperty('--subtitleHoveredBorder', clientTheme.subtitleHoveredBorder[clientName]);
document.documentElement.style.setProperty('--sliderGradient1', clientTheme.sliderGradient1[clientName]);
document.documentElement.style.setProperty('--sliderGradient2', clientTheme.sliderGradient2[clientName]);
document.documentElement.style.setProperty('--sliderGradient3', clientTheme.sliderGradient3[clientName]);
document.documentElement.style.setProperty('--sliderGradient4', clientTheme.sliderGradient4[clientName]);
document.documentElement.style.setProperty('--primaryColor', clientTheme.primaryColor[clientName]);
document.documentElement.style.setProperty('--secondaryColor', clientTheme.secondaryColor[clientName]);
document.documentElement.style.setProperty('--backgroundColor', clientTheme.backgroundColor[clientName]);
document.documentElement.style.setProperty('--buttonBorder', clientTheme.buttonBorder[clientName]);
document.documentElement.style.setProperty('--buttonShadow', clientTheme.buttonShadow[clientName]);
document.documentElement.style.setProperty('--footerTextColor', clientTheme.footerTextColor[clientName]);
document.documentElement.style.setProperty('--sliderRail', clientTheme.sliderRail[clientName]);
document.documentElement.style.setProperty('--fontFamily', clientTheme.fontFamily[clientName]);

const urlParams = new URLSearchParams(window.location.search);
const price = parseInt(urlParams.get('price'), 10);
const provinceId = parseInt(urlParams.get('provinceId'), 10);
const source = urlParams.get('source');

if (Number.isNaN(price)) {
  throw new Error('Invalid parameter "price"');
}

if (Number.isNaN(provinceId)) {
  throw new Error('Invalid parameter "provinceId"');
}

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <App price={price} provinceId={provinceId} source={source} />
  </I18nextProvider>,
  document.getElementById('root')
);
