const colors = {
  primary: {
    cbre: '#006a4d',
    vivendex: '#006A8E',
    anzizu: '#00408F'
  },
  primarySoft: {
    cbre: '#00a657',
    vivendex: '#4387a4',
    anzizu: '#0168ad'
  },
  primarySofter: {
    cbre: '#69be28',
    vivendex: '#75a6bd',
    anzizu: '#4596cc'
  },
  primarySoftest: {
    cbre: '#bfd857',
    vivendex: '#a3c3d2',
    anzizu: '#5e8ac0'
  },
  primaryExtraSoft: {
    cbre: '#d1ebbd',
    vivendex: '#d1e1e8',
    anzizu: '#89afdd'
  },
  primaryDark: {
    cbre: '#004b35',
    vivendex: '#004055',
    anzizu: '#002c63'
  },
  secondary: {
    cbre: '#ffffff',
    vivendex: '#f7f5f4',
    anzizu: '#f7f5f4'
  },
  secondaryDark: {
    cbre: '#e6e8ec',
    vivendex: '#e6e2df',
    anzizu: '#e6e2df'
  }
};

export default {
  firstBorder: {
    cbre: colors.primarySoftest.cbre,
    vivendex: colors.primarySofter.vivendex,
    anzizu: colors.primarySofter.anzizu
  },
  secondBorder: {
    cbre: colors.primarySofter.cbre,
    vivendex: colors.primarySoft.vivendex,
    anzizu: colors.primarySoft.anzizu
  },
  jumbotronBackground: {
    cbre: colors.primary.cbre,
    vivendex: colors.primary.vivendex,
    anzizu: colors.primary.anzizu
  },
  jumbotronColor: {
    cbre: colors.secondary.cbre,
    vivendex: colors.secondary.vivendex,
    anzizu: colors.secondary.anzizu
  },
  subtitleBorder: {
    cbre: '#b1d1c8',
    vivendex: colors.primarySofter.vivendex,
    anzizu: colors.primarySofter.anzizu
  },
  subtitleHoveredBorder: {
    cbre: '#208442',
    vivendex: colors.primarySoft.vivendex,
    anzizu: colors.primarySoft.anzizu
  },
  sliderGradient1: {
    cbre: colors.primarySoft.cbre,
    vivendex: colors.primary.vivendex,
    anzizu: colors.primary.anzizu
  },
  sliderGradient2: {
    cbre: '#68c157',
    vivendex: colors.primarySoft.vivendex,
    anzizu: colors.primarySoft.anzizu
  },
  sliderGradient3: {
    cbre: '#89ca57',
    vivendex: colors.primarySofter.vivendex,
    anzizu: colors.primarySofter.anzizu
  },
  sliderGradient4: {
    cbre: colors.primarySoftest.cbre,
    vivendex: colors.primarySoftest.vivendex,
    anzizu: colors.primarySoftest.anzizu
  },
  primaryColor: {
    cbre: colors.primary.cbre,
    vivendex: colors.primary.vivendex,
    anzizu: colors.primary.anzizu
  },
  secondaryColor: {
    cbre: colors.primaryDark.cbre,
    vivendex: colors.primaryDark.vivendex,
    anzizu: colors.primaryDark.anzizu
  },
  backgroundColor: {
    cbre: colors.secondary.cbre,
    vivendex: colors.secondary.vivendex,
    anzizu: colors.secondary.anzizu
  },
  buttonBorder: {
    cbre: colors.primaryExtraSoft.cbre,
    vivendex: colors.primaryExtraSoft.vivendex,
    anzizu: colors.primaryExtraSoft.anzizu
  },
  buttonShadow: {
    cbre: colors.primarySofter.cbre,
    vivendex: colors.primarySofter.vivendex,
    anzizu: colors.primarySofter.anzizu
  },
  footerTextColor: {
    cbre: colors.primary.cbre,
    vivendex: colors.primary.vivendex,
    anzizu: colors.primary.anzizu
  },
  sliderRail: {
    cbre: colors.secondaryDark.cbre,
    vivendex: colors.secondaryDark.vivendex,
    anzizu: colors.secondaryDark.anzizu
  },
  fontFamily: {
    cbre: 'Montserrat',
    vivendex: 'Muli',
    anzizu: 'Raleway'
  }
};
