import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import './styles.css';
import logo from './logo.svg';
import config from '../../config';

const Footer = ({ t }) => (
  <div className="footer">
    {t('footer.title')}
    <a
      // eslint-disable-next-line react/jsx-no-target-blank
      target="_blank"
      href={`${config.url}/?utm_source=${
        config.source[process.env.REACT_APP_CLIENT_NAME]
      }&utm_medium=floating&utm_campaign=listing`}
    >
      <img src={logo} alt="Trioteca" className="logo" />
    </a>
  </div>
);

Footer.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(Footer);
