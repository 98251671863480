const minSavingsPercent = purpose => (purpose === 'occasional' ? 0.45 : 0.35);

export const minimumRecommendedSavingsAmount = (price, purpose) => price * minSavingsPercent(purpose);

const fetchMortgageOption = async ({ price, provinceId, term, savings, purpose, income, interestType }) => {
  const response = await fetch('https://trioteca.com/api/v2/widget/get-proposals', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data: {
        price,
        province_id: provinceId,
        mortgage_years: term,
        occupancy: purpose,
        savings,
        income,
        interest_type: interestType
      }
    })
  });

  const { results } = await response.json();

  if (!results) {
    throw new Error('Unexpected response: missing results');
  }

  if (!results.F || !results.V) {
    throw new Error('No hemos encontrado resultados con los datos introducidos, prueba a modificarlos');
  }

  return { results };
};

const fetchMortgageOptions = async options => {
  const { price, purpose } = options;
  const minRecommendedSavingsAmount = minimumRecommendedSavingsAmount(price, purpose);
  let { savings } = options;
  let comments = null;

  if (savings < minRecommendedSavingsAmount) {
    savings = minRecommendedSavingsAmount;
    comments = `El banco necesitará un importe mínimo de ahorro de ${new Intl.NumberFormat('es-ES', {
      style: 'currency',
      maximumSignificantDigits: 1,
      currency: 'EUR'
    }).format(minRecommendedSavingsAmount)}.`;
  }
  const { results } = await fetchMortgageOption({ ...options, savings });
  const { F: fixed, V: variable, provinceExpenses } = results;
  return {
    comments,
    options: [
      {
        ...fixed,
        name: 'Hipoteca Fija',
        tin: fixed.mortgageCondition.interest_rate ? `${fixed.mortgageCondition.interest_rate}% TIN` : null,
        province_expenses: provinceExpenses
      },
      {
        ...variable,
        name: 'Hipoteca Variable',
        tin: variable.mortgageCondition.interest_rate
          ? `Euribor + ${variable.mortgageCondition.interest_rate + variable.mortgageCondition.differential}%`
          : null,
        province_expenses: provinceExpenses
      }
    ]
  };
};

export default fetchMortgageOptions;
